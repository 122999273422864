import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";
import Login from "./Login";
import firebase from "firebase/app";
import globalStore from "./GlobalStore";
import Dashboard from "./Dashboard";
import PostLogin from "./PostLogin";
import Footer from "./Footer";
import Header from "./Header";
import Loading from "./Loading";
import ReportReview from "./ReportReview";

const config = {
	apiKey: "AIzaSyCspph1Oyd9tPtbnL2A4MMFgG7Xj0PUV4A",
	authDomain: "mhd-v2.firebaseapp.com",
	databaseURL: "https://mhd-v2.firebaseio.com",
	projectId: "mhd-v2",
	storageBucket: "mhd-v2.appspot.com",
	messagingSenderId: "278077013599"
};
firebase.initializeApp(config);

class App extends Component {
	render() {
		return (
			<div className="App">
				<BrowserRouter>
					<HttpsRedirect>
						<div>
							<Header />
							<Routes />
							<Footer />
						</div>
					</HttpsRedirect>
				</BrowserRouter>
			</div>
		);
	}
}
class Routes extends Component {
	constructor(props) {
		super(props);

		this.state = { redirect: null, showData: false, loading: true };
	}

	componentDidMount() {
		if (window.location.pathname.includes("/admin/report")) {
			this.setState({ loading: false });
		} else {
			firebase.auth().onAuthStateChanged(user => {
				if (user) {
					setTimeout(() => {
						//this has to be in a set timeout or else you get weird routing
						//errors with the router package. don't ask me why.
						globalStore.firebaseUser = {
							firebaseID: user.uid,
							email: user.email,
							displayName: user.displayName
						};
					}, 1);
					this.setState({
						redirect: "/dashboard",
						loading: false
					});
				} else {
					setTimeout(() => {
						//this has to be in a set timeout or else you get weird routing
						//errors with the router package. don't ask me why.
						globalStore.firebaseUser = null;
					}, 1);
					this.setState({ loading: false });
				}
			});
			setTimeout(() => {
				if (
					!globalStore.firebaseUser &&
					window.location.pathname !== "/postLogin" &&
					window.location.pathname !== "/data"
				) {
					this.setState({ redirect: "/" });
				}
			}, 250);
		}
	}

	render() {
		return (
			<div>
				{this.state.redirect ? <Redirect to={this.state.redirect} /> : null}

				{this.state.loading ? (
					<Loading />
				) : (
					<div style={{ paddingTop: 50 }} className="component">
						<Switch>
							<Route exact path="/dashboard" component={Dashboard} />
							<Route exact path="/postLogin" component={PostLogin} />
							<Route
								exact
								path="/admin/reportReview"
								component={ReportReview}
							/>
							<Route exact path="/" component={Login} />
						</Switch>
					</div>
				)}
			</div>
		);
	}
}

export default App;
