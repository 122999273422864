import React, { Component } from "react";
import autoBind from "react-autobind";
import { observer } from "mobx-react";
import { Redirect } from "react-router";
import globalStore from "./GlobalStore";
import firebase from "firebase/app";
import Loading from "./Loading";

export default observer(
	class PostLogin extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {};
		}

		signOut(evt) {
			evt.preventDefault();
			firebase.auth().signOut();
			this.props.history.push("/");
		}

		render() {
			if (globalStore.loginError) {
				return (
					<div className="container text-center" style={{ marginTop: 75 }}>
						<h1>You aren't authorized to use this app.</h1>
						<h4>
							<a href="/" onClick={this.signOut}>
								Sign out
							</a>
						</h4>
					</div>
				);
			} else if (globalStore.firebaseUser) {
				return <Redirect to="/dashboard" />;
			} else {
				return (
					<div className="container text-center" style={{ marginTop: 75 }}>
						<Loading />
					</div>
				);
			}
		}
	}
);
