import React, { Component } from "react";
import autoBind from "react-autobind";
import API from "./API";
import { Table } from "reactstrap";
import moment from "moment-timezone";

export default class ReportReview extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = { reports: [] };

		if (process.env.REACT_APP_ENV === "staging") {
			this.apiURL = "https://api.staging.myhealthyday.com/mhdAdminV2";
		} else {
			this.apiURL =
				process.env.NODE_ENV === "development"
					? "http://localhost:3000/mhdAdminV2"
					: "https://api.myhealthyday.com/mhdAdminV2";
		}

		if (process.env.REACT_APP_ENV === "staging") {
			this.userApiURL = "https://api.staging.myhealthyday.com/mhd/2.0";
		} else {
			this.userApiURL =
				process.env.NODE_ENV === "development"
					? "http://localhost:3000/mhd/2.0"
					: "https://api.myhealthyday.com/mhd/2.0";
		}
	}

	findGetParameter(parameterName) {
		let result = null,
			tmp = [];

		window.location.search
			.substr(1)
			.split("&")
			.forEach(function(item) {
				tmp = item.split("=");
				if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
			});
		return result;
	}

	componentDidMount() {
		API(
			`/reportReviewList?password=${this.findGetParameter("password")}`,
			"GET",
			{},
			data => {
				this.setState({ reports: data.reports });
			},
			this.apiURL
		);
	}

	render() {
		return (
			<div>
				<h1>Reports:</h1>
				<Table>
					<thead>
						<tr>
							<th>Template</th>
							<th>Email</th>
							<th>Timestamp</th>
							<th>Sent</th>
							<th>User Data</th>
							<th>Report</th>
							<th>Send</th>
						</tr>
					</thead>
					<tbody>
						{this.state.reports.map((report, index) => {
							return (
								<tr key={index}>
									<td>{report.template}</td>
									<td>{report.user.email}</td>
									<td>{moment(report.requestTimestamp).format("lll")}</td>
									<td>
										{report.sentTime
											? moment(report.sentTime).format("lll")
											: "NO"}
									</td>
									<td>
										<a
											href={`${this.apiURL}/userData/${
												report.user.id
											}?password=${this.findGetParameter("password")}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											Open
										</a>
									</td>
									<td>
										{report.token ? (
											<a
												href={`https://cdn.myhealthyday.com/reports/${
													report.token
												}/MyHealthyReport.pdf`}
												target="_blank"
												rel="noopener noreferrer"
											>
												Open
											</a>
										) : null}
									</td>
									<td>
										{report.token ? (
											<p
												style={{ color: "#007bff", cursor: "pointer" }}
												onClick={() => {
													if (window.confirm("Ya sure? No takesy backsies!")) {
														API(
															`mhd/2.0/sendReport?password=${this.findGetParameter(
																"password"
															)}`,
															"POST",
															{ userID: report.user.id, requestID: report.id },
															() => {
																this.componentDidMount();
															},
															false,
															this.userApiURL
														);
													}
												}}
											>
												Send
											</p>
										) : null}
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>
		);
	}
}
