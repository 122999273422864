import React, { Component } from "react";

export default class Loading extends Component {
	constructor(props) {
		super(props);

		this.state = {
			statusText: " "
		};

		this.timeouts = [];
	}

	componentDidMount() {
		this.timeouts.push(
			setTimeout(() => {
				this.setState({
					statusText: "Almost there..."
				});
			}, 5000)
		);

		this.timeouts.push(
			setTimeout(() => {
				this.setState({
					statusText: "Getting closer..."
				});
			}, 10000)
		);

		this.timeouts.push(
			setTimeout(() => {
				this.setState({ statusText: "Just a bit more..." });
			}, 15000)
		);

		this.timeouts.push(
			setTimeout(() => {
				this.setState({ statusText: "Something might've gone wrong..." });
			}, 60000)
		);

		this.timeouts.push(
			setTimeout(() => {
				this.setState({ statusText: "Something's definitely wrong..." });
			}, 120000)
		);
	}

	componentWillUnmount() {
		this.timeouts.map(timeout => {
			return clearTimeout(timeout);
		});
	}

	render() {
		return (
			<div className="loading">
				<div className=" three-balls">
					<div className="ball ball1" />
					<div className="ball ball2" />
					<div className="ball ball3" />
				</div>
				<h4
					className="statusText"
					style={{ marginTop: 15, fontStyle: "italic" }}
				>
					{this.state.statusText}
				</h4>
			</div>
		);
	}
}
