import React, { Component } from "react";
import autoBind from "react-autobind";
import globalStore from "./GlobalStore";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import * as firebase from "firebase/app";
import "firebase/auth";
import API from "./API";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

import { Card, Input, Col, Row, Container } from "reactstrap";

export default observer(
	class Login extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				email: "",
				password: "",
				existingUser: null,
				errorMessage: null,
				buttonText: "Continue",
				dob: null,
				firstName: "",
				lastName: "",
				zipCode: "",
				redirect: null
			};
		}

		handleChange(event) {
			const name = event.target.name;
			const value = event.target.value;
			this.setState({
				[name]: value
			});
		}

		onChange = date => this.setState({ dob: date });
		componentDidMount() {}

		signInAsync = async () => {
			if (this.state.buttonText === "Continue") {
				API(
					"mhd/2.0/accountCheck",
					"POST",
					{ email: this.state.email, admin: true },
					data => {
						if (data.existing) {
							this.setState({ existingUser: true, buttonText: "Sign In" });
						} else if (data.newAccount) {
							this.setState({ existingUser: false, buttonText: "Sign Up" });
						} else if (data.unauthorized) {
							this.setState({
								errorMessage: "This email address is not authorized to use MHD."
							});
						}
					},
					false
				);
			} else if (this.state.buttonText === "Sign Up") {
				let state = this.state;
				if (!state["firstName"]) {
					this.setState({ errorMessage: "Please enter your first name." });
				} else if (!state["lastName"]) {
					this.setState({ errorMessage: "Please enter your last name." });
				} else if (!state["zipCode"]) {
					this.setState({ errorMessage: "Please enter your zip code." });
				} else if (!state["dob"]) {
					this.setState({ errorMessage: "Please enter your Date of Birth." });
				} else {
					API(
						"mhd/2.0/signup",
						"POST",
						{
							zipCode: this.state.zipCode,
							dob: this.state.dob,
							firstName: this.state.firstName,
							lastName: this.state.lastName,
							password: this.state.password,
							email: this.state.email
						},
						data => {
							firebase
								.auth()
								.signInWithEmailAndPassword(
									this.state.email,
									this.state.password
								)
								.then(() => {
									this.setState({
										redirect: "/dashboard"
									});
								})
								.catch(error => this.setState({ errorMessage: error.message }));
						},
						false
					);
				}
			} else {
				firebase
					.auth()
					.signInWithEmailAndPassword(this.state.email, this.state.password)
					.then(async () => {
						this.setState({
							redirect: "/dashboard"
						});
					})
					.catch(error => this.setState({ errorMessage: error.message }));
			}
		};

		render() {
			return globalStore.firebaseUser ? (
				<Redirect to={this.state.redirect} />
			) : (
				<Container>
					<Row>
						<Col xs={1} lg={3} />
						<Col xs={10} lg={6}>
							<Card className="loginCard">
								<Row>
									<Col />
									<Col>
										<img
											src="/MHDLogoDark.png"
											width="90%"
											className="img-responsive"
											alt="MyHealthyDay logo"
										/>
									</Col>
									<Col />
								</Row>

								{this.state.errorMessage && (
									<p className="errorText text-center">
										{this.state.errorMessage}
									</p>
								)}

								<Input
									type="text"
									name="email"
									placeholder="Email address"
									value={this.state.email}
									onChange={this.handleChange}
								/>

								{this.state.existingUser !== null ? (
									<Input
										type="password"
										name="password"
										placeholder="Password"
										value={this.state.password}
										onChange={this.handleChange}
										required
									/>
								) : null}
								{this.state.existingUser === false
									? [
											<Input
												type="text"
												name="firstName"
												placeholder="First Name"
												value={this.state.firstName}
												onChange={this.handleChange}
												required={true}
											/>,
											<Input
												type="text"
												name="lastName"
												placeholder="Last Name"
												value={this.state.lastName}
												onChange={this.handleChange}
												required={true}
											/>,
											<Input
												name="zipCode"
												type="number"
												placeholder="Zip Code"
												value={this.state.zipCode}
												onChange={this.handleChange}
												required={true}
											/>,
											<div className="dob">
												<DatePicker
													dateFormat="yyyy/MM/dd"
													placeholderText={"Date of Birth"}
													selected={this.state.dob}
													onChange={this.onChange}
													className="datePicker"
													showMonthDropdown
													showYearDropdown
													minDate="1900-01-01"
													maxDate={moment()
														.subtract(18, "years")
														.toDate()}
												/>
											</div>
									  ]
									: null}
								<Row>
									<Col xs="auto" />
									<Col xs="12" lg="10">
										<button
											className="squared-blue "
											onClick={this.signInAsync}
										>
											{this.state.buttonText}
										</button>
									</Col>
									<Col xs="auto" />
								</Row>
							</Card>
						</Col>
						<Col xs={1} lg={3} />
					</Row>
				</Container>
			);
		}
	}
);
