import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import autoBind from "react-autobind";
import firebase from "firebase/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from "reactstrap";
import { observer } from "mobx-react";
import globalStore from "./GlobalStore";

export default observer(
	class Header extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				collapsed: true,
				redirect: null
			};
		}
		toggleNavbar() {
			this.setState({
				collapsed: !this.state.collapsed
			});
		}

		render() {
			if (this.state.redirect) {
				return <Redirect to={this.state.redirect} />;
			}
			return (
				<div>
					<Navbar
						color="inverse"
						light
						expand="xs"
						className="sticky-top navbar navbar-fixed-top"
						id="mainNav"
					>
						<NavbarBrand href="" className="navbar-brand">
							<img
								src="/horizontal-dark.png"
								width="40%"
								className="d-inline-block align-top"
								alt="MyHealthyDay logo"
							/>
						</NavbarBrand>
						<NavbarToggler
							onClick={this.toggleNavbar}
							className="mr-2 navbar-toggler"
							aria-controls="navbarResponsive"
							aria-expanded="false"
							aria-label="Toggle navigation"
						/>
						{globalStore.firebaseUser ? (
							<Collapse isOpen={!this.state.collapsed} navbar>
								<Nav className="ml-auto navbar-nav" navbar>
									<UncontrolledDropdown nav inNavbar>
										<DropdownToggle nav>
											<p className="nav-item">Settings</p>
										</DropdownToggle>
										<DropdownMenu right>
											<DropdownItem>
												<NavLink
													className="nav-link"
													href=""
													onClick={async () => {
														if (firebase.auth().currentUser) {
															firebase.auth().signOut();
														}
														this.setState({ redirect: "/" });
													}}
												>
													Logout &nbsp; &nbsp; &nbsp;
													<span>
														<FontAwesomeIcon icon={faSignOutAlt} />
													</span>
												</NavLink>
											</DropdownItem>
											<DropdownItem divider />
											<p
												style={{
													marginBottom: 0,
													textAlign: "right",
													fontSize: 10,
													color: "gray",
													marginRight: 10
												}}
											>
												v. {process.env.REACT_APP_VERSION}
											</p>
										</DropdownMenu>
									</UncontrolledDropdown>
								</Nav>
							</Collapse>
						) : (
							<p
								style={{
									marginBottom: 0,
									textAlign: "right",
									fontSize: 10,
									color: "gray",
									marginRight: 10
								}}
								className="ml-auto navbar-nav"
							>
								v. {process.env.REACT_APP_VERSION}
							</p>
						)}
					</Navbar>
				</div>
			);
		}
	}
);
