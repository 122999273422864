import React, { Component } from "react";
import autoBind from "react-autobind";
import firebase from "firebase/app";
import { Container } from "reactstrap";

export default class Footer extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {};
	}

	render() {
		return (
			<Container className="fixed-bottom text-right">
				<a
					href="/"
					onClick={evt => {
						evt.preventDefault();
						firebase.auth().signOut();
						window.location = "/";
					}}
				>
					Sign Out
				</a>
			</Container>
		);
	}
}
