import React, { Component } from "react";
import autoBind from "react-autobind";
import { Row, Col, Container } from "reactstrap";
import { List, SimpleListItem } from "@rmwc/list";
import {
	DataTable,
	DataTableContent,
	DataTableHead,
	DataTableBody,
	DataTableHeadCell,
	DataTableRow,
	DataTableCell
} from "@rmwc/data-table";
import "./ParticipantDetails.css";
import API from "./API";
import globalStore from "./GlobalStore";
import Loading from "./Loading";
import moment from "moment-timezone";
import isemail from "email-validator";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

export default class Dashboard extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			activeParticipant: null,
			emailAddressTextField: "",
			currentUsers: null,
			authorizedUsers: null,
			invitedUsers: null,
			sessionCount: null,
			smsSendHistory: []
		};
	}

	componentDidMount() {
		API("mhdAdminV2/users", "GET", null, data => {
			this.setState({
				currentUsers: data.users,
				authorizedUsers: data.authorizedUsers,
				invitedUsers: data.invitedUsers
			});
		});

		API("mhdAdminV2/smsSendHistory", "GET", null, data => {
			this.setState({
				smsSendHistory: data.history
			});
		});
	}

	participantSelected(activeParticipant) {
		this.setState({ activeParticipant, sessionCount: "Loading..." });
		API(`mhdAdminV2/sessionCount/${activeParticipant.id}`, "GET", {}, data => {
			this.setState({ sessionCount: data.sessionCount });
		});
	}

	addByEmail() {
		globalStore.snackbarMessage = "Saving...";
		//split on , ; space new line or tab then filter out anything that's not a valid email
		const emailArray = this.state.emailAddressTextField
			.split(/[,;\s\n\r\t]/g)
			.filter(string => {
				return isemail.validate(string);
			});

		API("mhdAdminV2/users", "POST", { authorizedUsers: emailArray }, data => {
			globalStore.snackbarMessage = `${data.numAdded} Participant${
				data.numAdded === 1 ? "" : "s"
			} Added`;

			this.setState({ authorizedUsers: data.authorizedUsers });
		});
	}

	removeAuthorizedUser(userID) {
		if (window.confirm("Are you sure you want to remove this participant?")) {
			API(`mhdAdminV2/users/${userID}`, "DELETE", null, data => {
				const authorizedUsers = this.state.authorizedUsers.filter(user => {
					return user.id !== userID;
				});

				this.setState({
					authorizedUsers: authorizedUsers
				});
			});
		}
	}

	resendInvite(userID) {
		API(`mhdAdminV2/users/sendEmail/${userID}`, "POST", null, data => {
			globalStore.snackbarMessage = `${data.numSent} Participant${
				data.numSent === 1 ? "" : "s"
			} Emailed`;
		});
	}

	sendInvites() {
		API("mhdAdminV2/users/sendEmails", "POST", {}, data => {
			globalStore.snackbarMessage = `${data.numSent} Participant${
				data.numSent === 1 ? "" : "s"
			} Emailed`;

			API("mhdAdminV2/users", "GET", null, data => {
				this.setState({
					currentUsers: data.users,
					authorizedUsers: data.authorizedUsers,
					invitedUsers: data.invitedUsers
				});
			});
		});
	}

	sendSMS() {
		API(
			"mhdAdminV2/sendSMS",
			"POST",
			{ numbers: this.state.smsNumbers },
			data => {
				globalStore.snackbarMessage = `${data.numSent} Participant${
					data.numSent === 1 ? "" : "s"
				} Texted`;
				this.setState({
					smsSendHistory: data.history
				});
			}
		);
	}

	render() {
		return (
			<Container id="participantDetails" style={{ marginBottom: 100 }}>
				<h1>Participants</h1>
				<h2>Current participants</h2>
				<Row>
					<Col xs={4}>
						<List twoLine>
							{!this.state.currentUsers ? (
								<Loading />
							) : (
								this.state.currentUsers.map(user => {
									return (
										<SimpleListItem
											text={user.displayName}
											key={user.id}
											secondaryText={user.email}
											meta="MORE"
											className={
												this.state.activeParticipant &&
												this.state.activeParticipant.id === user.id
													? "active"
													: null
											}
											onClick={this.participantSelected.bind(this, user)}
										/>
									);
								})
							)}
						</List>
					</Col>
					{this.state.activeParticipant ? (
						<Col>
							<div>
								<div
									className="cardPrimary"
									style={{ backgroundColor: "#A92D25" }}
								>
									<div className="cardTitle">
										{this.state.activeParticipant.displayName}
									</div>
								</div>
								<div className="cardSupportingText">
									<Row>
										<Col xs={4}>Email:</Col>
										<Col>{this.state.activeParticipant.email}</Col>
									</Row>
									<Row>
										<Col xs={4}>Session Count:</Col>
										<Col>{this.state.sessionCount}</Col>
									</Row>
								</div>
							</div>
						</Col>
					) : null}
				</Row>
				<Row style={{ marginTop: 50 }}>
					<Col xs={6}>
						<h2>Invited participants</h2>
						<List twoLine>
							{!this.state.invitedUsers ? (
								<Loading />
							) : (
								this.state.invitedUsers.map(user => {
									return (
										<div key={user.id}>
											<ContextMenuTrigger id={`rightClickMenu-${user.id}`}>
												<SimpleListItem
													className="authorizedUser"
													text={user.email}
													key={user.id}
													secondaryText={`Invited: ${moment(
														user.createdAt
													).format("l")}`}
													meta="REMOVE"
													// className={
													// 	this.state.activeParticipant &&
													// 	this.state.activeParticipant.id === user.id
													// 		? "active"
													// 		: null
													// }
													onClick={this.removeAuthorizedUser.bind(
														this,
														user.id
													)}
												/>
											</ContextMenuTrigger>
											<ContextMenu id={`rightClickMenu-${user.id}`}>
												<MenuItem
													onClick={this.resendInvite.bind(this, user.id)}
												>
													Send Invite
												</MenuItem>
												<MenuItem
													onClick={this.removeAuthorizedUser.bind(
														this,
														user.id
													)}
												>
													Remove
												</MenuItem>
											</ContextMenu>
										</div>
									);
								})
							)}
						</List>
					</Col>
				</Row>
				<Row style={{ marginTop: 50 }}>
					<Col>
						<h2>Uploaded participants</h2>
						<List twoLine>
							{!this.state.authorizedUsers ? (
								<Loading />
							) : (
								this.state.authorizedUsers.map(user => {
									return (
										<div key={user.id}>
											<ContextMenuTrigger id={`rightClickMenu-${user.id}`}>
												<SimpleListItem
													className="authorizedUser"
													text={user.email}
													key={user.id}
													secondaryText={`Invited: ${moment(
														user.createdAt
													).format("l")}`}
													meta="REMOVE"
													// className={
													// 	this.state.activeParticipant &&
													// 	this.state.activeParticipant.id === user.id
													// 		? "active"
													// 		: null
													// }
													onClick={this.removeAuthorizedUser.bind(
														this,
														user.id
													)}
												/>
											</ContextMenuTrigger>
											<ContextMenu id={`rightClickMenu-${user.id}`}>
												<MenuItem
													onClick={this.resendInvite.bind(this, user.id)}
												>
													Send Invite
												</MenuItem>
												<MenuItem
													onClick={this.removeAuthorizedUser.bind(
														this,
														user.id
													)}
												>
													Remove
												</MenuItem>
											</ContextMenu>
										</div>
									);
								})
							)}
						</List>
					</Col>
					<Col xs={6}>
						<h2>Add participants</h2>
						<div>
							<h4>By Email</h4>
							<div className="cardSupportingText">
								<textarea
									label="Email addresses"
									rows="4"
									value={this.state.emailAddressTextField}
									onChange={evt => {
										this.setState({ emailAddressTextField: evt.target.value });
									}}
									className="form-control"
									placeholder="Enter email addresses, separated by commas, for the members
									you'd like to add. Each participant will receive an email with
									instructions and and link for completing registration."
								/>
							</div>
							<div>
								<button
									className="button btn-primary"
									onClick={this.addByEmail}
								>
									Add Emails
								</button>
							</div>
						</div>
						<div style={{ marginTop: 15 }}>
							<h4>Send Invites</h4>
							<div>
								<button
									className="button btn-primary"
									onClick={this.sendInvites}
								>
									Send Emails
								</button>
							</div>
							<div className="cardSupportingText" style={{ marginTop: 50 }}>
								<textarea
									label="Phone numbers"
									rows="4"
									value={this.state.smsNumbers}
									onChange={evt => {
										this.setState({ smsNumbers: evt.target.value });
									}}
									className="form-control"
									placeholder="Enter phone numbers separated by line breaks without a country code or spaces"
								/>
							</div>
							<div>
								<button className="button btn-primary" onClick={this.sendSMS}>
									Send text messages
								</button>
							</div>
						</div>
					</Col>
				</Row>
				<Row style={{ marginTop: 50 }}>
					<Col xs={6}>
						<h2>SMS Send History</h2>
						<DataTable>
							<DataTableContent>
								<DataTableHead>
									<DataTableRow>
										<DataTableHeadCell>Phone Number</DataTableHeadCell>
										<DataTableHeadCell>Timestamp</DataTableHeadCell>
									</DataTableRow>
								</DataTableHead>
								<DataTableBody>
									{this.state.smsSendHistory.map(history => {
										return (
											<DataTableRow key={history.id}>
												<DataTableCell>{history.phoneNumber}</DataTableCell>
												<DataTableCell>
													{moment(history.timestamp).format("l LT")}
												</DataTableCell>
											</DataTableRow>
										);
									})}
								</DataTableBody>
							</DataTableContent>
						</DataTable>
					</Col>
				</Row>
			</Container>
		);
	}
}
